import Translate from "../resources";
import TeamMember from "./team-member";

function TeamPage() {
  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <h3 className="text-center text-nowrap fw-bolder header mb-4">
          {Translate("doctors")}
        </h3>
        <div className="row justify-content-around row-cols-auto row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-auto">
          <div className="col">
            <TeamMember
              name="Dr. Dt. Ayşe Saatman Yıldız"
              title_2={Translate("partner")}
              title_1={Translate("periodontology")}
              instagramaddress="https://www.instagram.com/dr.aysesaatman/"
              instagramaccount="@dr.aysesaatman"
              resumeaddress="drdtaysesaatmanyildiz"
              photo={`/team-photos/ayse_saatman_yildiz_300x300.webp`}
            ></TeamMember>
          </div>
          <div className="col">
            <TeamMember
              name="Dt. Görkem Güner"
              title_2={Translate("partner")}
              title_1={Translate("endodontics")}
              instagramaddress="https://www.instagram.com/drgorkemguner/"
              instagramaccount="@drgorkemguner"
              resumeaddress="dtgorkemguner"
              photo={`/team-photos/gorkem_guner_300x300.webp`}
            ></TeamMember>
          </div>
          <div className="col">
            <TeamMember
              name="Dt. Ezgi Kaya"
              title_1={Translate("orthodontics")}
              resumeaddress="dtezgikaya"
              instagramaddress="https://www.instagram.com/drezgi.kaya/"
              instagramaccount="@drezgi.kaya"
              photo={`/team-photos/ezgi_kaya_300x300.webp`}
            ></TeamMember>
          </div>
          <div className="col">
            <TeamMember
              name="Dt. Selena Su Denizer"
              title_1={Translate("pedodontics")}
              instagramaddress="https://www.instagram.com/dt.selenasudenizer/"
              instagramaccount="@dt.selenasudenizer"
              resumeaddress="dtselenasudenizer"
              photo={`/team-photos/selena_su_denizer_300x300.webp`}
            ></TeamMember>
          </div>
        </div>
      </div>

      <div className="row">
        <h3 className="text-center text-fluid text-nowrap fw-bolder header mb-4">
          {Translate("staff")}
        </h3>
        <div className="row justify-content-around row-cols-xs-1 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-auto">
          <div className="col">
            <TeamMember
              name="Aleyna Buse Demir"
              title_1={Translate("patientAdmissionOfficer")}
              photo={`/team-photos/aleyna_buse_demir_300x300.webp`}
            ></TeamMember>
          </div>
          <div className="col">
            <TeamMember
              name="Nazlı Nazan İmat"
              title_1={Translate("dentalAssistant")}
              title_2={Translate("anesthesiaTechnician")}
              photo={`/team-photos/nazli_nazan_imat_300x300.webp`}
            ></TeamMember>
          </div>
          <div className="col">
            <TeamMember
              name="Semanur Bozbay"
              title_1={Translate("dentalAssistant")}
              photo={`/team-photos/semanur_bozbay_300x300.webp`}
            ></TeamMember>
          </div>
          <div className="col">
            <TeamMember
              name="Bakiye Yazgan"
              title_1={Translate("hygieneOfficer")}
              photo={`/team-photos/bakiye_yazgan_300x300.webp`}
            ></TeamMember>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamPage;
