import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PricesData from "./treatment_prices.json";
import { useTranslation } from "react-i18next";

interface Treatment {
  group: string;
  treatment: string;
  price: string;
  currency: string;
}

interface TreatmentGroup {
  groupName: string;
  groupFilter: string;
}

function GroupRow(
  allTreatments: Treatment[],
  groupName: string,
  groupFilter: string
) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        }}
      >
        <TableCell
          sx={{
            borderBottom: "unset",
          }}
        >
          <Typography
            sx={{ color: "#846d57" }}
            variant="h6"
            gutterBottom
            component="div"
          >
            {groupName}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            borderBottom: "unset",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{ padding: 0 }}
          colSpan={6}
        >
          <Box
            sx={{
              margin: 1,
              paddingRight: 1,
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 1,
            }}
          >
            <Table size="small" aria-label="purchases">
              <TableBody>
                {allTreatments
                  .filter((treatment) => treatment.group === groupFilter)
                  .map((treatment) => (
                    <TableRow
                      sx={{
                        padding: 0,
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#846d57",
                          paddingRight: 2,
                          paddingLeft: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {t(`${treatment.treatment}`)}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#846d57",
                          padding: 0,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textAlign: "end",
                        }}
                      >
                        {typeof treatment.price === "string" &&
                        treatment.price.includes("-")
                          ? `${treatment.price
                              .split("-")
                              .map((price) =>
                                parseInt(price.trim(), 10).toLocaleString(
                                  "tr-TR"
                                )
                              )
                              .join(" - ")} ${treatment.currency}`
                          : `${parseInt(
                              treatment.price.toString(),
                              10
                            ).toLocaleString("tr-TR")} ${treatment.currency}`}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Prices() {
  const { t } = useTranslation();
  const [treatments, setTreatments] = useState<Treatment[]>([]);
  const treatmentGroups: TreatmentGroup[] = [
    {
      groupName: t("oralAndMaxillofacialSurgery"),
      groupFilter: "CERRAHİ",
    },
    { groupName: t("periodontology"), groupFilter: "PERİODONTOLOJİ" },
    {
      groupName: t("treatmentAndEndodontics"),
      groupFilter: "TEDAVİ VE ENDODONTİ",
    },
    { groupName: t("pedodontics"), groupFilter: "PEDODONTİ" },
    { groupName: t("prosthesis"), groupFilter: "PROTEZ" },
  ];

  useEffect(() => {
    setTreatments(PricesData);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="container-fluid">
      <div className="row">
        <Box
          sx={{
            borderBottom: "unset",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            key="tax_message"
            sx={{ color: "#846d57", fontWeight: "bold" }}
            fontSize={16}
            gutterBottom
            marginTop={2}
            component="div"
          >
            {t("pricesTextVAT")}
          </Typography>
          <Typography
            key="treatment_message"
            sx={{ color: "#846d57" }}
            fontSize={16}
            gutterBottom
            component="div"
          >
            {t("pricesTextExamination")}
          </Typography>
          <Typography
            key="xray_message"
            sx={{ color: "#846d57" }}
            fontSize={16}
            gutterBottom
            component="div"
          >
            {t("pricesTextXray")}
          </Typography>
        </Box>
      </div>
      <div className="row">
        {treatmentGroups.map((treatmentGroup) =>
          GroupRow(
            treatments,
            treatmentGroup.groupName,
            treatmentGroup.groupFilter
          )
        )}
      </div>
    </div>
  );
}
